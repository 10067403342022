import { useEffect } from "react";
import { useRef } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import videoMask from "./video-mask.svg";
// import placeholder from "./gambar-video.jpg";
import VideoPopup from "./VideoPopup";
import './style.scss'


function PlayButton() {
    const buttonCircle = useRef();
    useEffect(() => {
        var BubbleAnimationEffect = function () {
            buttonCircle.current.style.borderTopLeftRadius = String(Math.round(Math.random() * 200 + 250) + 'px');
            buttonCircle.current.style.borderTopRightRadius = String(Math.round(Math.random() * 200 + 250) + 'px');
            buttonCircle.current.style.borderBottomLeftRadius = String(Math.round(Math.random() * 200 + 250) + 'px');
            buttonCircle.current.style.borderBottomRightRadius = String(Math.round(Math.random() * 200 + 250) + 'px');
        };
        BubbleAnimationEffect();
        const intervalId = setInterval(BubbleAnimationEffect, 2000);
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    return <div className="play-button">
        <i className="fa-solid fa-play"></i>
        <div className="bubble-circle" ref={buttonCircle}></div>
    </div>
}

export default function TrailerVideo({ mask, placeholder, url }) {
    return <div className="trailer-video">
        {mask && <div className="top-curve">
            <img src={videoMask} alt="" />
        </div>}
        <div className="video">
            <img src={placeholder} alt="" />
            <Popup trigger={PlayButton} position="center" modal={true} className="video-popup">
                {close => <VideoPopup url={url} close={close} />}
            </Popup>

        </div>

    </div>
}