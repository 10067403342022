import FirstBanner from "../../components/FirstBanner";
import WhatsOnList from "../../components/ThingsToDo/WhatsOnList";
import splash_img from "../../assets/splash.webp";
import useFetch from "../../customFunction/useFetch";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 }
  }
}
const WhatsOn = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { data, isPending, error } = useFetch(apiUrl + 'whatson');
  document.title = "What's On | Wahoo Waterworld";
  return (
    <motion.div className="position-relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <FirstBanner>
        <div className="button-title mx-auto mb-2">What's On</div>
        <h2 className="secondary-title text-center text-white">Jangan lewatkan semua keseruan di Wahoo Waterworld!</h2>
      </FirstBanner>
      <div className="section pt-1">
        <div className="container">
          {isPending && ' '}
          {error && error.message}
          {data && <WhatsOnList whatOns={data} />}
        </div>
      </div>
      {data && <img className="splash-bottom" src={splash_img} />}
    </motion.div>
  );
};
export default WhatsOn;
