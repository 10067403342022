import './style.scss';
import { Link } from "react-router-dom"
import ButtonLink from "../../ui/ButtonLink";
import ImageClip from '../../ui/ImageClip';
import parse from 'html-react-parser';

const EventVenueList = ({ eventVenues, className }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <div>
            {eventVenues.map((eventVenue) => (
                <div key={eventVenue.id} className='list-item pb-6 mb-6 px-4'>
                    <div>
                        {/* <Link to={eventVenue.url}> */}
                        <ImageClip src={baseUrl+eventVenue.image_url} alt={eventVenue.name} />
                        {/* </Link> */}
                    </div>
                    <div className="content-list event-velue-list">
                        <div className="big-heading text-left">
                            {/* <Link to={eventVenue.url}> */}
                            <div className="button-title text-normal bg-yellow mb-3">{eventVenue.name}</div>
                            {/* </Link> */}
                        </div>
                        <div className='info-venue'>
                            <div className="venue-list">
                                <i className="ico fa-solid fa-square"></i>
                                Size: {eventVenue.large}m<sup>2</sup>
                            </div>
                            <div className="venue-list">
                                <i className="ico fa-solid fa-user"></i>
                                Capacity: {eventVenue.capacity}pax
                            </div>
                        </div>
                        <div>
                            {parse(eventVenue.content)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default EventVenueList;