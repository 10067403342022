import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash.webp";
import SitemapList from "../components/SitemapList";
import vip from "../assets/sitemap/vip.svg";
import chill from "../assets/sitemap/chill.svg";
import river from "../assets/sitemap/river.svg";
import storage from "../assets/sitemap/storage.svg";
import atm from "../assets/sitemap/atm.svg";
import prayer from "../assets/sitemap/prayer.svg";
import gift from "../assets/sitemap/gift.svg";
import photo from "../assets/sitemap/photo.svg";
import food from "../assets/sitemap/food.svg";
import map from "../assets/sitemap/map.webp";
import mapNew from "../assets/sitemap/wahoo-map.png";
import mapWithDesc from "../assets/sitemap/map-new.jpg";
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const Sitemap = () => {
    const services = [
        {
            name: "Ticketing",
        },
        {
            name: "ATM",
        },
        {
            name: "Storage Counter",
        },
        {
            name: "To Up Kiosk",
        },
        {
            name: "Refund",
        },
        {
            name: "Nursery Room",
        },
        {
            name: "Toilet",
        },
        {
            name: "Locker",
        },
        {
            name: "Shower",
        },
        {
            name: "Rental",
        },
        {
            name: "Exit",
        },
        {
            name: "Laguna Cafe",
        },
        {
            name: "Sally's Souvenir Shop",
        },
        {
            name: "Odie's Food Street",
        },
        {
            name: "Tata Retreat",
        },
        {
            name: "Tutu Pavillion",
        },
        {
            name: "Abyss Garden",
        },
        {
            name: "Oz Snack",
        },
        {
            name: "Baker Street",
        },
        {
            name: "Dock",
        },
        {
            name: "First Aid",
        },
        {
            name: "Toilet Lagoon & Nursery Room",
        },
        {
            name: "Mushola",
        },

    ];
    const attractions = [
        {
            name: "Rocket Blast",
            link: "/ride-attraction/rocket-blast",
        },
        {
            name: "Wave Pool",
            link: "/ride-attraction/wave-pool",
        },
        {
            name: "Warm Pool",
            link: "/ride-attraction/warm-pool",
        },
        {
            name: "Lagoon Pool",
            link: "/ride-attraction/lagoon-pool",
        },
        {
            name: "Boomerango",
            link: "/ride-attraction/bomerango",
        },
        {
            name: "Super Bowl",
            link: "/ride-attraction/superbowl",
        },
        // {
        //     name: "Splash Zone",
        //     link: "/ride-attraction/splash-zone",
        // },
        // {
        //     name: "Play Zone",
        //     link: "/ride-attraction/play-zone",
        // },
        // {
        //     name: "Rain Fortress",
        //     link: "/ride-attraction/rain-fortress",
        // },
        // {
        //     name: "Aqua Zone",
        //     link: "/ride-attraction/aqua-zone",
        // },
        {
            name: "The Caterpillar",
            link: "/ride-attraction/the-caterpillar",
        },
        {
            name: "Abyss",
            link: "/ride-attraction/abyss",
        },
        {
            name: "Lazy River",
            link: "/ride-attraction/lazy-river",
        },
        // {
        //     name: "Feeding the Animal",
        //     link: "/ride-attraction/abyss",
        // },
        // {
        //     name: "Water Recreation",
        //     link: "/ride-attraction/lazy-river",
        // },

    ];
    document.title = "Map | Wahoo Waterworld";

    return (
        // <motion.div className="position-relative" style={{ backgroundColor: '#c0eaff' }}
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner style={{ zIndex: 1 }} color1="blue" color2="lightblue">
                <div className="mb-2 button-title bg-yellow mx-auto">Site Map</div>
                {/* <h1 className="secondary-title text-center text-white px-big-1">Know your way around the park</h1> */}
                <h1 className="secondary-title text-center text-white px-big-1">Jelajahi Wahoo Waterworld dengan mudah</h1>
            </FirstBanner>
            <div className="section position-relative pt-1 z-index pb-0">
                <div className="container">
                    {/* <div className="map-zoom">
                        <button className="zoom-in">+</button>
                        <button disabled className="zoom-out">−</button>
                    </div> */}
                    <div className="map-detail mt-big-1 mb-big-3">
                        <img className="w-100" alt="Wahoo Map" src={mapNew} />
                    </div>
                    <div className="">
                        <div className="layout-3 mbl-layout-2 map-legend pb-big-2 ">
                            <div className="wrapper">
                                <div style={{ backgroundColor: "#ea1d1f" }} className="button-title text-normal mb-3">Attractions</div>
                                <ol className="mb-2">
                                    <li>RocketBlast</li>
                                    <li>Wave Pool</li>
                                    <li>Warm Pool</li>
                                    <li>Lagoon Pool</li>
                                    <li>Boomerango</li>
                                    <li>Super Bowl</li>
                                    <li>The Caterpillar</li>
                                    <li>Abyss</li>
                                    <li>Lazy River</li>
                                </ol>
                                <p className="mb-2"><b>Kiddie Island:</b></p>
                                <ol start="10">
                                    <li>Splash Zone</li>
                                    <li>RainFortess</li>
                                    <li>Aqua Zone</li>
                                    <li>Play Zone</li>
                                </ol>
                            </div>
                            <div className="wrapper">
                                <div className="button-title bg-yellow text-normal mb-3">Guest Services</div>
                                <ol start="a" type="A">
                                    <li> Lobby Drop Off </li>
                                    <li> Entrance </li>
                                    <li> Wahoo Plaza </li>
                                    <li> Ticket Counter </li>
                                    <li> ATM Center </li>
                                    <li>  Nursery Room </li>
                                    <li>  Mushola </li>
                                    <li> Toilet </li>
                                    <li>  Locker, Shower & Toilet </li>
                                    <li>  Top Up Kiosk </li>
                                    <li>  Photo Anjungan </li>
                                    <li>  Storage Counter </li>
                                    <li>  Refund Counter </li>
                                    <li>  Exit </li>
                                    <li>  Sally’s Souvenir Shop </li>
                                    <li>  Odie’s Food Street </li>
                                    <li> Tata Retreat </li>
                                    <li>  Abyss Garden </li>
                                    <li>  Rental Counter </li>
                                    <li>  Tutu Pavillion </li>
                                    <li>  Kiddie Shower & Toilet </li>
                                    <li>  Oz Snack </li>
                                    <li>  Laguna Cafe </li>
                                    <li>  First Aid </li>
                                    <li>  Parking </li>
                                </ol>
                            </div>
                            <div className="wrapper">
                                <div className="button-title text-normal mb-3">Guest Services</div>
                                <ul>
                                    <li>Feeding the Animal</li>
                                    <li>Lake Recreation</li>
                                    <li>Baker Street</li>
                                    <li>Playgorund</li>
                                </ul>
                            </div>
                            {/* <SitemapList title="Ride and Attractions" bgColor="#f6a21d" textColor="white">
                                {attractions.map((attraction, index) => (
                                    <div key={index} className="list-of-items">
                                        <div className="bullets" style={{ backgroundColor: 'red', color: 'white' }}>
                                            {index + 1}
                                        </div>
                                        <div className="listName">{attraction.name}</div>
                                    </div>
                                ))}
                            </SitemapList> */}
                            {/* <SitemapList bgColor="#f6a21d" title="Services" textColor="white">
                                {services.map((service, index) => (
                                    <div key={index} className="list-of-items">
                                        <div className="bullets" style={{ backgroundColor: '#f8ee5d' }}>
                                            {String.fromCharCode(index+65)}
                                        </div>
                                        <div className="listName">{service.name}</div>
                                    </div>
                                ))}
                            </SitemapList> */}
                        </div>
                    </div>
                </div>
                {/* <div className="spacer-footer" style={{ backgroundColor: '#c0eaff' }}></div> */}
            </div>
            {/* <img className="splash-bottom z-index" src={splash_img} /> */}
        </motion.div>
    );
}

export default Sitemap;