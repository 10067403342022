export default function VideoPopup({close, url='https://www.youtube.com/embed/y3-uXy-CcvU'}){
    return <div className="modal">
        <div className="modal-content">
            <div className="close-button" onClick={close}>
                <i class="fa-solid fa-xmark"></i>
            </div>
            <div className="heading">
                <h3 className="pt-3 pt-md-5">Don't Miss The Fun!</h3>
            </div>
            <div className="iframe-container">
                <iframe src={url} title="Wahoo Waterworld" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
}