import './style.scss';

export default function ButtonBubble({children,className}){
    const buttonClass = ["button",className];

    return (
        <button className={`button-bubble ${className || ''} `}>
            <div className="text">{children}</div>
            <span className="shape">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>
    );
}