import './style.scss';
import { Link } from "react-router-dom"
import ButtonLink from "../../ui/ButtonLink";
import ImageClip from '../../ui/ImageClip';

const WhatsOnList = ({ whatOns, className }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <div>
            {whatOns.map((whatOn) => (
                <div key={whatOn.id} className='list-item pb-6 mb-6 px-4'>
                    <div>
                        <Link to={whatOn.seo_url}>
                            <ImageClip src={baseUrl+whatOn.image_url} alt={whatOn.title} />
                        </Link>
                    </div>
                    <div className="content-list">
                        <div className="text-red text-medium mb-3">{whatOn.periode_time}</div>
                        <div className="big-heading text-left">
                            <Link to={whatOn.seo_url}>
                                <h2 className='sub-title on-link'>{whatOn.name}</h2>
                            </Link>
                        </div>
                        {whatOn.description && <p className='mb-4'>{whatOn.description}</p> }
                        {/* <ButtonLink link={whatOn.url} /> */}
                        <ButtonLink link={whatOn.seo_url} />
                    </div>
                </div>
            ))}
        </div>
    );
}
export default WhatsOnList;