import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash-blue.svg";
import ovo from "../assets/cashless/ovo.svg";
import gopay from "../assets/cashless/gopay.svg";
import dana from "../assets/cashless/dana.svg";
import linkaja from "../assets/cashless/link-aja.svg";
import mastercard from "../assets/cashless/mastercard.svg";
import visa from "../assets/cashless/visa.svg";
import { Link } from "react-router-dom";
import useFetch from "../customFunction/useFetch";
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const Cashless = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'cashless');
    document.title = "Cashless | Wahoo Waterworld";

    return (
        <>
            <motion.div className="position-relative bg-color-lightblue"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <FirstBanner color1="blue" color2="lightblue">
                    <div className="button-title bg-yellow" >Cashless Payment</div>
                </FirstBanner>
                <div className="container pb-big-1">
                    <h1 className="text-center main-title text-blue">These are the payment methods for cashless payment</h1>
                    <div className="cashless-div py-big-3 layout-6 z-index position-relative">
                        {
                            data && data.map((cashless, index) => (
                                <div key={cashless.id} className="cashless-list">
                                    {/* <Link to={`${cashless.seo_url}`}> */}
                                        <img src={baseUrl + cashless.image_url} />
                                        <h2 className="text-blue third-title text-center">{cashless.name}</h2>
                                    {/* </Link> */}
                                </div>
                            ))
                        }
                    </div>
                    <div className="spacer-footer bg-color-lightblue"></div>
                </div>
                <img className="splash-bottom" src={splash_img} />
            </motion.div>
        </>
    );
}

export default Cashless;