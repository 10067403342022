import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import Bullet from "./Bullet";
import "./style.scss";

//assets
import waveTop from './wave-top.svg';
import waveBottom from './wave-bottom.svg';
import splash from "./splash.png";
import dolphin from "./edgy-dolphin.png";


// Placeholders, delete on production
import placeholder_image1 from "../../assets/ticket/ticket-1.png";

let rotate_degree = 15;
const drag_sensitivity = 5;
const drag_trigger_sensitivity = 5;


export default function RideCircularSlider({data}) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [slidePos, setSlidePos] = useState(1);
    const circularSliderContainer = useRef();
    const sliderRotateHolder = useRef();
    const clientWidth = useRef();
    useEffect(() => {
        function setRotateDegree() {
            if (window.innerWidth < 768) {
                rotate_degree = 8;
            } else {
                rotate_degree = 15;
            }
        }
        setRotateDegree();
        window.addEventListener('resize', setRotateDegree);
        return () => {
            window.removeEventListener('resize', setRotateDegree);
        }
    }, [])

    const navigate = useCallback((direction, to = null) => {
        let newSlidePos;
        if (to !== null) {
            newSlidePos = to;
        } else {
            newSlidePos = direction === "<" ? slidePos - 1 : slidePos + 1;
        }
        setSlidePos(() => Math.max(Math.min(data.length - 1, newSlidePos), 0));
    }, [slidePos])

    useEffect(() => {
        let clicked = false;
        let dragged = false;
        let drag_pos = 0;
        let clickPos = 0;
        const slider = circularSliderContainer.current;

        //set client width
        clientWidth.current = window.innerWidth;
        function resizeHandler() {
            clientWidth.current = window.innerWidth;
        }
        window.addEventListener('resize', resizeHandler);

        //set drag handler
        const mouseDownHandler = function (event) {
            if (event.target.closest('.box')) {
                clicked = true;
            }
            clickPos = event.clientX || event.touches[0].clientX;
            sliderRotateHolder.current.classList.add('dragging');
        }
        const mouseUpHandler = function (event) {
            sliderRotateHolder.current.classList.remove('dragging');
            const moved = Math.round(drag_pos / rotate_degree) * -1;


            if (moved === 0) {
                sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
            } else {
                navigate("", slidePos + moved);
            }
            if (!dragged) {
                event.target.closest('.image') && setSlidePos(parseInt(event.target.closest('.image').getAttribute('slide-key')));
            }
            clicked = false;
            dragged = false;
        }
        const mouseMoveHandler = function (event) {
            if (clicked) {
                const clientX = event.clientX || event.touches[0].clientX;
                if (!dragged) {
                    if (Math.abs(clientX - clickPos) > drag_trigger_sensitivity) {
                        dragged = true;
                    }
                }
                drag_pos = (clientX - clickPos) / clientWidth.current * rotate_degree * drag_sensitivity;
                const drag_rotate_degree = ((slidePos * rotate_degree * -1) + drag_pos);
                sliderRotateHolder.current.style.transform = "rotateZ(" + drag_rotate_degree + "deg)";
            }
        }

        const clickEvent = ['mousedown', 'touchstart'];
        const stopEvent = ['mouseup', 'touchend', 'touchcancel'];
        const moveEvent = ['mousemove', 'touchmove'];

        clickEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseDownHandler, { capture: false });
        });
        stopEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseUpHandler, { capture: false });
        })
        moveEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseMoveHandler, { capture: false });
        })

        return () => {
            clickEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseDownHandler);
            });
            stopEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseUpHandler);
            })
            moveEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseMoveHandler);
            })
        }
    }, [navigate, slidePos])

    useEffect(() => {
        sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
    }, [slidePos]);

    return (
        <div className="ticket-circular-slider-section">
            <div className="wave wave-top">
                <div className="spacer"></div>
                <img className="img-wave" src={waveTop} alt="" />
                <div className="ornaments">
                    <div className="left dolphin">
                        <img src={dolphin} alt="" className="float-anim" />
                    </div>
                    <div className="right splash">
                        <img src={splash} alt="" />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="big-heading margin-bottom">
                    <div className="sub-title">Ticket</div>
                    <h3 className="main-title text-orange">
                        {/* Book Directly and Get Better Deals! */}
                        BELI LANGSUNG DAN DAPATKAN HARGA TERBAIK!
                    </h3>
                </div>
            </div>
            <div className="relative">
                <div className="navigation-button">
                    <button
                        onClick={(event) => { navigate.bind(event, "<")() }}
                        className="button-prev"
                    >
                        <div className="arrow"></div>
                    </button>
                    <button
                        onClick={(event) => { navigate.bind(event, ">")() }}
                        className="button-next"
                    >
                        <div className="arrow"></div>
                    </button>
                </div>
                <div className="ticket-circular-slider-container" ref={circularSliderContainer}>
                    <div className="box-container" ref={sliderRotateHolder}>
                        {data.map((el, idx) => (
                            <div className="box" key={idx} >
                                {/* {slidePos === idx && 
                                <Link to={`/ticket/${el.seo_url}`} >
                                </Link>} */}
                                {/* <Link to="../booking" >
                                </Link> */}
                                {/* <a target="_blank" href={process.env.REACT_APP_BOOKING}> */}
                                    {/* <Link to='/booking'> */}
                                    <Link to='/ticket-and-package'>
                                        <div className="image" slide-key={idx}>
                                            <img loading="lazy" src={baseUrl+el.image_url} alt={el.name} />
                                        </div>
                                    </Link>
                                {/* </a> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Bullet items={data} current={slidePos} setSliderPos={setSlidePos} />
            <div className="wave wave-bottom">
                <img className="img-wave" src={waveBottom} alt="" />
            </div>
        </div>
    );
}
