
import useFetch from "../customFunction/useFetch";
import PageDetail from "../components/PageDetail";
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const SpecialOfferDetail = () => {
    const location = useLocation().pathname.split("/").pop();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'news?seo=' + location);
    if (data && data){
        document.title = data.name+" | Wahoo Waterworld";
    }
    return (
        <>
            {isPending && ' '}
            {error && error.message}
            {
                data && <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <PageDetail data={data} />
                </motion.div>
            }
        </>
    );
}

export default SpecialOfferDetail;