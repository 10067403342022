import { useState, useEffect } from 'react';
import './style.scss';
import useFetch from '../../customFunction/useFetch';
const Fader = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'metadata');
    const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' });
    const [textFade, setTextFade] = useState([
        { text: <Text1 /> },
        { text: <Text2 /> },
    ]);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (data && data['sold_out']) {
                setTextFade([
                    { text: <Text1 /> },
                    { text: <Text2 /> },
                    { text: <Text3 /> },
                ]);
            }
            setFadeProp({ fade: 'fade-out' });
            setTimeout(() => {
                setFadeProp({ fade: '' })
                setIndex(index + 1)
                if (index >= textFade.length - 1) {
                    setIndex(0);
                }
            }, (1000));
        }, 4000);
        return () => {
            clearInterval(interval);
        };
    });
    return (
        <div className={`fade fade-in ${fadeProp.fade}`}>
            {textFade[index].text}
        </div>
    );
}
const Text1 = () => {
    return (
        <>
            <h2>
                <span className="white anim">
                    Seluncuran air
                </span>
                <span className="orange anim">
                    tercepat dan pertama
                </span>
                <span className="blue anim">di Asia Tenggara</span>
            </h2>
        </>
    )
}
const Text2 = () => {
    return (
        <>
            <h2>
                <span className="white anim">
                    Petualangan air seru
                </span>
                <span className="blue anim"> untuk sekeluarga</span>
            </h2>
        </>
    )
}
const Text3 = () => {
    var date = new Date();
    var tahun = date.getFullYear();
    var bulan = date.getMonth();
    var tanggal = date.getDate();
    switch (bulan) {
        case 0: bulan = "Januari"; break;
        case 1: bulan = "Februari"; break;
        case 2: bulan = "Maret"; break;
        case 3: bulan = "April"; break;
        case 4: bulan = "Mei"; break;
        case 5: bulan = "Juni"; break;
        case 6: bulan = "Juli"; break;
        case 7: bulan = "Agustus"; break;
        case 8: bulan = "September"; break;
        case 9: bulan = "Oktober"; break;
        case 10: bulan = "November"; break;
        case 11: bulan = "Desember"; break;
    }
    var fulldate = tanggal + ' ' + bulan + ' ' + tahun;
    return (
        <>
            <h2>
                <span className="white anim">
                    Tiket {fulldate}
                </span>
                <span className="blue anim"> Sold Out</span>
            </h2>
        </>
    )
}
export default Fader;