import waveYellow from '../assets/top-yellow-wave.svg';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import useFetch from "../customFunction/useFetch";
import parse from 'html-react-parser';
import { motion } from "framer-motion";
import Seo from '../components/Seo';
import { useEffect } from 'react';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}


const CashlessDetail = () => {
    const location = useLocation().pathname.split("/").pop();
    const apiUrl = process.env.REACT_APP_API_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'cashless');

    const currentPage = data && data.filter(current => {
        return current.seo_url === location;
    });
    const otherPayment = data && data.filter(other => {
        return other.seo_url !== location;
    })
    if (currentPage && currentPage[0]) {
        document.title = currentPage[0].name + " | Wahoo Waterworld";  
    }

    return (
        <>
            {isPending && ' '}
            {error && error.message}
            {currentPage && currentPage.length > 0 ?
                <>
                    <motion.div className="position-relative cashless-detail"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div className="bg-color-blue section  pb-big-2">
                            <div className="container text-white text-center">
                                <h1 className="main-title mb-2 px-big-1">How To Use {currentPage[0].name} on <br></br>Wahoo Water World</h1>
                                <div className='w-75 px-big-5 mbl-px-big-1 w-mbl-100 mx-auto'>
                                    {parse(currentPage[0].description)}
                                </div>
                                <div className="spacer-2"></div>
                            </div>
                        </div>

                        <div className="section yellow pt-2 pb-2 text-blue">
                            <div className="top-wave-yellow ">
                                <img src={waveYellow} />
                            </div>
                            <div className="container">
                                <h5 className="text-center secondary-title mb-big-1">
                                    How To Use other cashless payment
                                </h5>
                                <div className="cashless-div justify-content-center layout-6 z-index position-relative">
                                    {
                                        otherPayment.map((cashless, index) => (
                                            <div key={index} className="cashless-list">
                                                <Link to={`/cashless/${cashless.seo_url}`}>
                                                    <img src={baseUrl + cashless.image_url} />
                                                    <h2 className="text-blue third-title text-center">{cashless.name}</h2>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="spacer-footer bg-color-yellow"></div>
                    </motion.div>
                </>
                : null}
        </>
    );
}

export default CashlessDetail;