import "./style.scss";
import defaultImage from "../../assets/from-top.jpg";
import { useState, useEffect } from "react";

const BannerImage = ({ image, className, title, wave, children }) => {
    const [top, setTop] = useState(null);
    useEffect(() => {
        // setTop((document.getElementById('header').offsetHeight * -1) /2);
    });
    return (
        <div className={`banner ${className ? className : ''}`} style={{ backgroundImage: `url(${image ? image : defaultImage})`, marginTop: top }}>
            <h1 className="text-banner">{title ? title : ''}</h1>
            {wave ?
                <div className="bottom-wave-banner">
                    <div className="waveblue"></div>
                    <div className="wavewhite"></div>
                </div>
                :
                ''
            }
            {children || ''}
        </div>
    );
}

export default BannerImage;