import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCreative, Autoplay } from "swiper";
import placeholder_banner from "../../assets/banner-img1.jpg";
import placeholder_banner1 from "../../assets/banner-img2.jpg";
import placeholder_banner2 from "../../assets/banner-img3.jpg";
import placeholder_banner3 from "../../assets/banner-img4.jpg";
import { motion} from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";

import "./style.scss";

export default function MainSlider({ data }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const images = [placeholder_banner, placeholder_banner1];
  const config = {
    autoplay: {
      delay: 3000,
    },
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    speed: 500,
    pagination: {
      clickable: true,
    },
    navigation: true,
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ["-20%", 0, -1],
      },
      next: {
        translate: ["100%", 0, 0],
      },
    },
    modules: [Pagination, Navigation, EffectCreative, Autoplay],
  };
  return (
    <Swiper {...config} className="main-banner">
      {
        data && data.length > 0 ?
          data.map((el, idx) => (
            <SwiperSlide key={idx}>
                <img className="dekstop-only" loading="lazy" src={baseUrl+el.image_url} alt={el.name} />
                <img className="mobile-only" loading="lazy" src={baseUrl+el.image_potrait_url} alt={el.name} />
            </SwiperSlide>
          ))
          : images.map((el, idx) => (
            <SwiperSlide key={idx}>
              <motion.div className="image"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.5}}
              >
                <img src={el} />
              </motion.div>
            </SwiperSlide>
          ))
      }
      <div className="banner-curve"></div>
    </Swiper>
  );
}
