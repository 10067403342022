import './style.scss';
import { gsap, Power2 } from "gsap";
import { useEffect } from 'react';
const FirstBanner = ({ children, color1, color2 }) => {
    const colorCode = {};
    colorCode['orange'] = '#df6b48';
    colorCode['blue'] = '#3d60a2';
    colorCode['lightgreen'] = '#cadc7d';
    colorCode['green'] = '#75862a';
    colorCode['lightblue'] = '#7ac5e5';
    colorCode['grey'] = '#c9e3f4';
    colorCode['yellow'] = '#f8ee5d';
    colorCode['lime'] = '#def455';
    const anim1 = "M 0,400 C 0,400 0,200 0,200 C 197.46666666666664,218.53333333333333 394.9333333333333,237.06666666666666 555,225 C 715.0666666666667,212.93333333333334 837.7333333333333,170.26666666666668 979,161 C 1120.2666666666667,151.73333333333332 1280.1333333333332,175.86666666666667 1440,200 C 1440,200 1440,400 1440,400 Z";
    const anim2 = "M 0,400 C 0,400 0,200 0,200 C 137.33333333333331,163.06666666666666 274.66666666666663,126.13333333333335 415,145 C 555.3333333333334,163.86666666666665 698.6666666666667,238.53333333333336 870,257 C 1041.3333333333333,275.46666666666664 1240.6666666666665,237.73333333333332 1440,200 C 1440,200 1440,400 1440,400 Z"
    const anim3 = "M 0,400 C 0,400 0,200 0,200 C 168,175.46666666666667 336,150.93333333333334 483,137 C 630,123.06666666666668 756,119.73333333333335 912,132 C 1068,144.26666666666665 1254,172.13333333333333 1440,200 C 1440,200 1440,400 1440,400 Z"
    const anim4 = "M 0,400 C 0,400 0,200 0,200 C 163.46666666666664,234.53333333333333 326.9333333333333,269.06666666666666 475,267 C 623.0666666666667,264.93333333333334 755.7333333333333,226.2666666666667 914,209 C 1072.2666666666667,191.7333333333333 1256.1333333333332,195.86666666666665 1440,200 C 1440,200 1440,400 1440,400 Z"
    const anim5 = "M 0,400 C 0,400 0,200 0,200 C 197.46666666666664,218.53333333333333 394.9333333333333,237.06666666666666 555,225 C 715.0666666666667,212.93333333333334 837.7333333333333,170.26666666666668 979,161 C 1120.2666666666667,151.73333333333332 1280.1333333333332,175.86666666666667 1440,200 C 1440,200 1440,400 1440,400 Z"
    useEffect(() => {
        let tl = gsap.timeline({ repeat: -1, yoyo: true });
        tl.to('.path-1', { attr: { d:  anim1  }, ease: Power2.ease, duration: 2 })
            .to('.path-1', { attr: { d:  anim2  }, ease: Power2.easeInOut, duration: 2 })
            .to('.path-1', { attr: { d:  anim3  }, ease: Power2.easeInOut, duration: 2 })
            .to('.path-1', { attr: { d:  anim4  }, ease: Power2.easeInOut, duration: 2 })
            .to('.path-1', { attr: { d:  anim5  }, ease: Power2.easeInOut, duration: 2 });
    
        let tl2 = gsap.timeline({ repeat: -1, yoyo: true });
        tl2.to('.path-2', { attr: { d:  anim1  }, ease: Power2.ease, duration: 2.5 })
            .to('.path-2', { attr: { d:  anim2  }, ease: Power2.easeInOut, duration: 2.5 })
            .to('.path-2', { attr: { d:  anim3  }, ease: Power2.easeInOut, duration: 2.5 })
            .to('.path-2', { attr: { d:  anim4  }, ease: Power2.easeInOut, duration: 2.5 })
            .to('.path-2', { attr: { d:  anim5  }, ease: Power2.easeInOut, duration: 2.5 });
    }, []);
    return (
        <div className="first-banner">
            <div className="main-color" style={{ backgroundColor: color1 ? colorCode[color1] : '#7ac5e5' }} ></div>
            <svg id="path1"  className={color1 ? color1 : ''} viewBox="0 0 1440 275" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M 0,400 C 0,400 0,200 0,200 C 197.46666666666664,218.53333333333333 394.9333333333333,237.06666666666666 555,225 C 715.0666666666667,212.93333333333334 837.7333333333333,170.26666666666668 979,161 C 1120.2666666666667,151.73333333333332 1280.1333333333332,175.86666666666667 1440,200 C 1440,200 1440,400 1440,400 Z"
                    stroke="none" strokeWidth="0" fill={color1 ? colorCode[color1] : '#7ac5e5'} className="path-wave path-1"
                    transform="rotate(-180 720 200)"></path>
            </svg>
            <svg id="path2"  className={color2 ? color2 : ''} viewBox="0 0 1440 275" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M 0,400 C 0,400 0,200 0,200 C 186.66666666666669,209.06666666666666 373.33333333333337,218.13333333333335 550,204 C 726.6666666666666,189.86666666666665 893.3333333333333,152.53333333333333 1040,148 C 1186.6666666666667,143.46666666666667 1313.3333333333335,171.73333333333335 1440,200 C 1440,200 1440,400 1440,400 Z"
                    stroke="none" strokeWidth="0" fill={color2 ? colorCode[color2] : '#c9e3f4'} className="path-wave path-2"
                    transform="rotate(-180 720 200)"></path>
            </svg>
            <div className="content-first-banner">
                {children}
            </div>
        </div>
    );
}

export default FirstBanner;