import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash.webp";
import ListDiv from "../components/ListDiv";
import useFetch from "../customFunction/useFetch";
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const Merchandise = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'merchandise');
    document.title = "Merchandise | Wahoo Waterworld";

    return (
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner color1="blue" color2="lightblue">
                <div className="button-title bg-yellow" >Merchandise</div>
            </FirstBanner>
            <div className="py-big-2 z-index position-relative">
                <div className="container">
                    <h2 className="main-title text-light-blue text-center px-big-1 mb-big-1">Bawa pulang keseruan Wahoo berupa suvenir unik dan menarik.</h2>
                    {isPending && ' '}
                    {error && error.message}
                    <div className="layout-3">
                        {data && <ListDiv data={data} />}
                    </div>
                </div>
            </div>
            <img className="splash-bottom" src={splash_img} />
        </motion.div>
    );
}

export default Merchandise;