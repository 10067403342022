import playsafe from '../../../assets/rules/playsafe.svg';
import kids from '../../../assets/rules/kids.svg';
import dress from '../../../assets/rules/dress.svg';
import nosmoking from '../../../assets/rules/no-smoking.svg';
import nocamera from '../../../assets/rules/no-camera.svg';
import images from '../../../assets/rules/image.svg';
import hotline from '../../../assets/rules/hotline.svg';
import noanimal from '../../../assets/rules/no-animals.svg';
import nofood from '../../../assets/rules/no-food.svg';


// const rulesData = [
//     {
//         image: playsafe,
//         title: "Play Safe",
//         desc: "Please follow the instructions from the lifeguard, and comply to Wahoo Waterworld's rules and regulations. For everyone's safety, mind your step and respect other guests' comfort.",
//     },
//     {
//         image: kids,
//         title: "KIDS' SAFETY",
//         desc: "Kids must be under the supervision of adults. "
//     },
//     {
//         image: dress,
//         title: "DRESS APPROPRIATELY",
//         desc: "Visitors must wear appropriate swimsuits to ensure comfort, safety and security."
//     },
//     {
//         image: nocamera,
//         title: "COMMERCIAL FOOTAGE",
//         desc: "Any professional footage or video shooting for commercial purposes must get an authorized permit from Wahoo Waterworld."
//     },
//     {
//         image: images,
//         title: "IMAGE RIGHTS",
//         desc: "You may be filmed, videotaped, or photographed by the waterpark representatives during your visit. Your admission to Wahoo Waterworld serves as your consent to the use of your image. "
//     },
//     {
//         image: hotline,
//         title: "EMERGENCY HOTLINE",
//         desc: "Call +6222 868 088 00 in case of accidents or emergency."
//     },
//     {
//         image: nosmoking,
//         title: "Smoke Free",
//         desc: "To create a comfortable and safe environment for all the guests, smoking is only permitted in the outdoor area of Laguna Café."
//     },
//     {
//         image: noanimal,
//         title: "No Pets & Dangerous Tools",
//         desc: "Pets and dangerous tools are not allowed to ensure guests' safety and comfort."
//     },
//     {
//         image: nofood,
//         title: "NO OUTSIDE FOOD & DRINKS",
//         desc: "Your belonging will be checked upon entry to ensure that you don't bring any food or drink from outside."
//     },
// ];
const rulesData = [
    {
        image: playsafe,
        title: "bermain dengan aman",
        desc: "Harap ikuti petunjuk dari pengawal renang, dan patuhi peraturan Wahoo Waterworld. Untuk keselamatan semua orang, harap berhati-hati dan jaga kenyamanan tamu lain.",
    },
    {
        image: kids,
        title: "keselamatan anak-anak",
        desc: "Anak-anak harus didampingi orang dewasa."
    },
    {
        image: dress,
        title: "berpakaian yang tepat",
        desc: "Pengunjung harus memakai pakaian renang yang sesuai untuk menjaga kenyamanan, keselamatan, dan keamanan."
    },
    {
        image: nocamera,
        title: "video komersil",
        desc: "Setiap pembuatan video profesional untuk keperluan komersil harus mendapatkan izin resmi dari Wahoo Waterworld."
    },
    {
        image: images,
        title: "hak pakai foto",
        desc: "Kamu bisa disorot, direkam, atau difoto oleh representatif taman saat berkunjung. Tiket masuk kamu ke Wahoo Waterworld dianggap sebagai persetujuan untuk memakai dokumentasi Anda. "
    },
    {
        image: hotline,
        title: "saluran telepon darurat",
        desc: "Telepon +6222 868 088 00 jika ada kecelakaan atau keadaan darurat."
    },
    {
        image: nosmoking,
        title: "bebas asap",
        desc: "Wahoo Waterworld adalah Kawasan Terbatas Merokok. Untuk menciptakan lingkungan yang nyaman bagi semua pengunjung, merokok hanya diperbolehkan di area khusus."
    },
    {
        image: noanimal,
        title: "hewan peliharaan & alat-alat berbahaya",
        desc: "Hewan peliharaan dan alat-alat berbahaya tidak diizinkan di dalam taman untuk menjaga keamanan dan kenyamanan pengunjung."
    },
    {
        image: nofood,
        title: "dilarang membawa makanan & minuman dari luar",
        desc: "Barang bawaan kamu akan diperiksa pada saat masuk untuk memastikan tidak ada makanan dan minuman dari luar."
    },
];
export default rulesData;