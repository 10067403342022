import FirstBanner from "../../components/FirstBanner";
import DiningList from "../../components/ThingsToDo/DiningList";
import splash_img from "../../assets/splash.webp";
import { useState, useEffect } from "react";
import useFetch from "../../customFunction/useFetch";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 }
  }
}
const Dining = () => {
  const [dinings, setDining] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { data, isPending, error } = useFetch(apiUrl + 'dinning');
  document.title = "Dining | Wahoo Waterworld";
  return (
    <motion.div className="position-relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <FirstBanner color1="yellow">
        <div className="button-title mx-auto mb-2">Dining</div>
        <h2 className="secondary-title text-center text-white">Variasi tempat makan yang menyajikan makanan lezat & minuman segar!</h2>
      </FirstBanner>
      <div className="section pt-1">
        <div className="container">
          {isPending && ' '}
          {error && error.message}
          {data && <DiningList noPointer={true} dinings={data} />}
        </div>
      </div>
      <img className="splash-bottom" src={splash_img} />
    </motion.div>
  );
};

export default Dining;
