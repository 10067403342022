import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import Bar from "./Bar";
import Bullet from "./Bullet";
import "./style.scss";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


// Mandatory assets
// Placeholders, delete on production
//Circular slidernya sendiri bisa dibuatin component sendiri lagi sih nantinya (buat dipake untuk ticket dan ride), ini awal2 beda antara 2 circular slider, habis revisi2 malah jadi mirip fitur2nya, sedangkan gw ngerjain udah terlanjur dipisah
let rotate_degree = 7;
const drag_sensitivity = 5;
const drag_trigger_sensitivity = 20;
export default function RideCircularSliderDetail({ attractions }) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const numberSlider = attractions.length > 5 ? 3 : 1;
    const [slidePos, setSlidePos] = useState(numberSlider);
    const [attraction, setAttraction] = useState(attractions);
    const circularSliderContainer = useRef();
    const sliderRotateHolder = useRef();
    const clientWidth = useRef();
    const navigate = useCallback((direction, to = null) => {
        let newSlidePos;
        if (to !== null) {
            newSlidePos = to;
        } else {
            newSlidePos = direction === "<" ? slidePos - 1 : slidePos + 1;
        }
        setSlidePos(() => Math.max(Math.min(attractions.length - 1, newSlidePos), 0));
    }, [slidePos])
    useEffect(() => {
        function setRotateDegree() {
            if (window.innerWidth < 768) {
                rotate_degree = 5;
            } else {
                rotate_degree = 7;
            }
        }
        setRotateDegree();
        window.addEventListener('resize', setRotateDegree);
        return () => {
            window.removeEventListener('resize', setRotateDegree);
        }
    }, [])
    useEffect(() => {
        let clicked = false;
        let drag_pos = 0;
        let clickPos = 0;
        let dragged = false;
        const slider = circularSliderContainer.current;
        //set client width
        clientWidth.current = window.innerWidth;
        function resizeHandler() {
            clientWidth.current = window.innerWidth;
        }
        window.addEventListener('resize', resizeHandler);
        //set drag handler
        const mouseDownHandler = function (event) {
            if (event.target.closest('.box')) {
                clicked = true;
            }
            clickPos = event.clientX || event.touches[0].clientX;
            sliderRotateHolder.current.classList.add('dragging');
        }
        const mouseUpHandler = function (event) {
            sliderRotateHolder.current.classList.remove('dragging');
            const moved = Math.round(drag_pos / rotate_degree) * -1;
            if (moved === 0) {
                sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
            } else {
                // console.log(moved);
                navigate("", slidePos + moved);
            }
            if (!dragged) {
                event.target.closest('.image') && setSlidePos(parseInt(event.target.closest('.image').getAttribute('slide-key'))); //+0 supaya jadi integer
            }
            clicked = false;
            dragged = false;
        }
        const mouseMoveHandler = function (event) {
            if (clicked) {
                const clientX = event.clientX || event.touches[0].clientX;
                if (!dragged) {
                    if (Math.abs(clientX - clickPos) > drag_trigger_sensitivity) {
                        dragged = true;
                    }
                }
                drag_pos = (clientX - clickPos) / clientWidth.current * rotate_degree * drag_sensitivity;
                const drag_rotate_degree = ((slidePos * rotate_degree * -1) + drag_pos);
                sliderRotateHolder.current.style.transform = "rotateZ(" + drag_rotate_degree + "deg)";
            }
        }
        const clickEvent = ['mousedown', 'touchstart'];
        const stopEvent = ['mouseup', 'touchend', 'touchcancel'];
        const moveEvent = ['mousemove', 'touchmove'];
        clickEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseDownHandler, { capture: false });
        });
        stopEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseUpHandler, { capture: false });
        })
        moveEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseMoveHandler, { capture: false });
        })
        return () => {
            clickEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseDownHandler);
            });
            stopEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseUpHandler);
            })
            moveEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseMoveHandler);
            })
        }
    }, [navigate, slidePos])
    useEffect(() => {
        // const attractionTitleCur = attractionTitle.current;
        setAttraction(attractions[slidePos]);
        sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
    }, [slidePos]);
    return (
        <>
            <div className="circular-slider-section detail">
                <div className="relative">
                    {/* <div className="navigation-button">
                    <button
                        onClick={(event) => { navigate.bind(event, "<")() }}
                        className="button-prev"
                    >
                        <div className="arrow"></div>
                    </button>
                    <button
                        onClick={(event) => { navigate.bind(event, ">")() }}
                        className="button-next"
                    >
                        <div className="arrow"></div>
                    </button>
                </div> */}
                    <div className="circular-slider-container" ref={circularSliderContainer}>
                        <div className="box-container" ref={sliderRotateHolder}>
                            <Gallery>
                                {attractions.map((el, idx) => (
                                    <Item
                                        original={baseUrl + el.url}
                                        thumbnail={baseUrl + el.url}
                                        width="800"
                                        height="600"
                                        // height="100%"
                                        key={idx}
                                    >
                                        {({ ref, open }) => (
                                            <div className="box" key={idx}>
                                                <img ref={ref} onClick={open} src={baseUrl + el.url} />
                                                {/* <div className="image"  ref={ref} onClick={open} style={{ backgroundImage: `url(${baseUrl}${el.url})` }} slide-key={idx}></div> */}
                                                {/* <img
                                                    src={`${baseUrl}${el.url}`}
                                                    slide-key={idx}
                                                /> */}
                                            </div>
                                        )}
                                    </Item>
                                ))}
                            </Gallery>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
