import FirstBanner from "../components/FirstBanner";
import WhatsOnList from "../components/ThingsToDo/WhatsOnList";
import splash_img from "../assets/splash.webp";
import { useState, useEffect } from "react";
import useFetch from "../customFunction/useFetch";
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const SpecialOffer = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'promo');
    document.title = "Special Offer | Wahoo Waterworld";

    return (
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner>
                <div className="button-title">Special Offer</div>
            </FirstBanner>
            <div className="section pt-1">
                <div className="container">
                    {isPending && ' '}
                    {error && error.message}
                    {data && <WhatsOnList whatOns={data} />}
                </div>
            </div>
            <img className="splash-bottom" src={splash_img} />
        </motion.div>
    );
};
export default SpecialOffer;
