import ImageClip from '../../components/ui/ImageClip';
import defaultImage from "../../assets/from-top.jpg";
import mason1 from "../../assets/mason/mason1.jpg";
import mason2 from "../../assets/mason/mason2.jpg";
import mason3 from "../../assets/mason/mason3.jpg";
import pineHotel from "../../assets/plan/mason-pine.svg";
import fiveMinutes from "../../assets/plan/5minutes.png";
import "./style.scss";
import RoomPackage from '../../components/RoomPackage';
import { useState, useEffect } from "react";
import FirstBanner from '../../components/FirstBanner';
import useFetch from '../../customFunction/useFetch';
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const Accomodation = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'accomodation');
    document.title = "Accomodation | Wahoo Waterworld";
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='position-relative'
        >
            <FirstBanner color1="lightgreen" color2="green">
                <div className="container">
                    <div className="accomodation-banner">
                        <img className='py-3' src={pineHotel} alt="Pine Hotel Logo" />
                        <p className='py-3' >
                        Pengunjung dari luar Bandung bisa memilih Mason Pine Hotel Bandung untuk akomodasi yang berkelas dan nyaman, hotel yang dikelilingi oleh pemandangan alam yang cantik.
                            {/* Guests from outside Bandung can opt for the scenic Mason Pine Hotel Bandung for classy and convenient accommodation, surrounded by picturesque natural wonders. */}
                        </p>
                    </div>
                </div>
            </FirstBanner>
            <div className="section mb-big-4 mbl-mb-big-1">
                <div className="container">
                    <div className='list-item mb-big-6 mbl-mb-big-1'>
                        <div>
                            <ImageClip src={defaultImage} />
                        </div>
                        <div className="content-list">
                            <img src={fiveMinutes} className="five-minutes" />
                            <p className='mb-4'> Dengan jarak hanya lima menit dari Wahoo Waterworld, hotel ini menawarkan kamar dan suite luas yang ideal untuk liburan keluarga, lengkap dengan balkon pribadi menghadap ke pemandangan gunung Parahyangan.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bg-color-lightgreen position-relative">
                <div className="banner-curve white on-top"></div>
                <div className="container ">
                    <div className='all-day-img'>
                        <img src={mason1} alt="Mason Pine - Kolam Renang" />
                        <img src={mason2} alt="Mason Pine - Lobby" />
                        <img src={mason3} alt="Mason Pine - Kamar Suite" />
                    </div>
                    <p className='py-big-3 px-big-3 mbl-py-big-1 mbl-px-big-1 secondary-title text-center mb-big-1'>Fasilitas yang ditawarkan termasuk <b>SANTAI All Day Dining Restaurant</b>, <b>Kids' Club</b> dengan berbagai fitur dan aktivitas untuk keluarga, dan banyak lagi</p>
                </div>
            </div>
            <div className="section  position-relative">
                <div className="top-wave-banner">
                    <div className="waveblue"></div>
                    <div className="wavewhite"></div>
                </div>
                <div className="button-title bg-color-green mx-auto mb-big-2 text-medium">Room Package</div>
                <div className="container">
                    {data && <RoomPackage rooms={data} />}
                </div>
            </div>
            <div className="spacer-footer bg-color-lightgreen"></div>
        </motion.div>
    );
}

export default Accomodation;