export default function Bar({ level, color }) {
    return <div className="bar">
        <div className="bar-inner" style={{ width: level * 20 + "%", backgroundColor: color }}>
        </div>
        <div className="bar-indicator">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}