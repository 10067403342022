import BannerImage from "../../components/BannerImage";
import splash_img from "../../assets/splash.webp";
import ButtonLink from "../../components/ui/ButtonLink";
import parse from 'html-react-parser';
const PageDetail = ({ data, shareButton, toContact, children }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <div className="position-relative">
            <BannerImage className="no-overlay mb-4" image={data.image_url && baseUrl + data.image_url}>
                <div className="banner-curve white"></div>
            </BannerImage>
            <div className="section container pb-0 pt-1 position-relative z-index">
                <div className="detail-page">
                    <div className="title-div">
                        <h5 className="date text-red mb-2">{data.periode_time}</h5>
                        <h1 className="text-blue mb-4">{data.name}</h1>
                        {
                            shareButton && <ButtonLink arrowColor="bg-orange" icon="fa-share-nodes" link="/contact-us">Contact US</ButtonLink>
                        }
                    </div>
                    <div className="content-div">
                        {data.content && parse(data.content)}
                        {toContact &&
                            <ButtonLink arrowColor="bg-orange" link="/contact-us">BUY NOW!</ButtonLink>
                        }
                    </div>
                </div>
            </div>
            {
                children ? children :  <img className="splash-bottom" src={splash_img} />
            }
        </div>
    );
}

export default PageDetail;