import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.7 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const Booking = () => {
    const urlBooking = 'https://booking.wahoowaterworld.co.id/widget/wahoo-waterworld/booking-options';
    return (
        <>
            <motion.div
                className="section position-relative pt-2"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit" >
                <div className="mbl-px-0 container">
                    <iframe src={urlBooking} title="Online Reservation"></iframe>
                    <div className="syarat">
                        <p className="third-title text-bold">Syarat & Ketentuan Pembelian Tiket Online:</p>
                        <ol>
                            <li>Tiket yang dipilih adalah sesuai dengan tanggal kunjungan.</li>
                            <li>Harga tiket sesuai dengan harga dari tiket dari tanggal yang dipilih.</li>
                            <li>Harga dapat berubah sewaktu - waktu tanpa pemberitahuan terlebih dahulu</li>
                            <li>Tanggal kedatangan sesuai dengan tanggal kunjungan saat pemesanan.</li>
                            <li>Harga belum termasuk deposit Gelang Cashless.</li>
                            <li>Tunjukan bukti pemesanan (QR Code) pada saat melakukan penukaran (Redeem).</li>
                            <li>Mohon pastikan kembali tanggal pemesanan dan jumlah tiket Anda sudah benar, karena tiket yang sudah dibeli TIDAK BISA DIBATALKAN (REFUND) DAN TIDAK BISA BERPINDAH TANGGAL.</li>
                            <li>Management dapat menunda atau menghentikan wahana air, jika terjadi cuaca atau situasi yang dianggap membahayakan pengunjung.</li>
                        </ol>
                        <p className="third-title text-bold">Jam Operasional:</p>
                        <ul>
                            <li>Buka setiap hari pukul 09.00 s/d 17.00 WIB</li>
                            <li>Kecuali ditetapkan lain oleh Manajemen melalui pemberitahuan terlebih dahulu.</li>
                        </ul>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Booking;