import FirstBanner from "../../components/FirstBanner";
import AccordionList from "../../components/Accordion";
import { useEffect, useState } from "react";
import CardSlider from "../../components/CardSlider";
import { Link, useLocation } from "react-router-dom";
import tipsData from "./static-data/tips";
import rulesData from "./static-data/rules";
import useFetch from "../../customFunction/useFetch";
import regulation from "../../assets/tips/regulation.jpg"
import { motion } from "framer-motion";
import parse from 'html-react-parser';
import gsap from "gsap";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const TipsOnWahoo = () => {
    const location = useLocation();
    const hash = location.hash;
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: dataNews, isPending: isPendingNews, error: errorNews } = useFetch(apiUrl + 'news');
    const { data: dataFaq, isPending: isPendingFaq, error: errorFaq } = useFetch(apiUrl + 'faq');
    document.title = "Tips On Wahoo | Wahoo Waterworld";
    useEffect(() => {
        if(hash && hash === "#regulation"){
            setTimeout(() => {
                var myElement = document.getElementById('regulation');
                myElement.scrollIntoView({ 
                    behavior: 'smooth' 
                  })
            }, 601);

        }
    }, [location]);
    return (
        <>
            <motion.div className="position-relative"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <FirstBanner color1="lime">
                    <div className="button-title">Tips on Wahoo</div>
                </FirstBanner>
                <div className="section pt-0 pb-0 mt-big-2 overflow-hidden">
                    <div className="container">
                        {/* {dataNews && <div className="button-title mx-auto bg-yellow mb-big-1">Article</div>} */}
                        {isPendingNews && ' '}
                        {errorNews && errorNews.message}
                        {dataNews && <CardSlider data={dataNews} />}
                        {dataFaq && <div className="button-title mx-auto bg-yellow mb-big-1">FAQ</div>}
                        {dataFaq && <AccordionList data={dataFaq} />}
                        {dataFaq && <a href="#div-section-tips"><h3 className="mt-big-2 mx-auto see-more secondary-title">See More  <i className="arrow fa-solid fa-arrow-down-long"></i></h3></a>}
                    </div>
                    <div className="position-relative mt-big-3 ">
                        <div className="top-wave-animation" id="div-section-tips"></div>
                        <div className="bg-color-blue text-white position-relative pt-3 pt-lg-5">
                            <div className="container">
                                <div className="button-title mx-auto mb-4 bg-yellow ">Tips</div>
                                <p className="mb-3 mb-lg-4 text-center">Rekreasi paling menyenangkan adalah rekreasi yang terencana dengan baik! Intip tip-tip berguna ini supaya pengalamanmu di Wahoo Waterworld lancar dan aman.</p>
                                <div className="tips-div mt-big-2">
                                    {tipsData.map((tips, index) => (
                                        <div key={index} className="tips-list">
                                            <div className="icon-div">
                                                <img src={tips.image} alt="" />
                                            </div>
                                            <div className="tips-content">
                                                <h5 className="secondary-title text-uppercase">{tips.title}</h5>
                                                <p>{parse(tips.desc)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="bottom-wave-animation"></div>
                    </div>
                    <div className="py-big-4">
                        <div className="container">
                            <div className="button-title mx-auto bg-yellow mb-big-2">Rules</div>
                            <div className="tips-div mt-big-2">
                                {rulesData.map((rule, index) => (
                                    <div key={index} className="tips-list">
                                        <div className="icon-div">
                                            <img src={rule.image} alt="" />
                                        </div>
                                        <div className="tips-content">
                                            <h5 className="secondary-title text-uppercase">{rule.title}</h5>
                                            <p>{rule.desc}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-big-4 mb-big-1" id="regulation">
                        <div className="container">
                            <div className="regulations-box bg-color-lightblue text-blue">
                                <div className="top-title">
                                    {/* <div className="button-title mx-auto bg-yellow">
                                        Regulation</div> */}
                                </div>
                                <div className="content d-none">
                                    <div className="borders-clip">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h2 className="py-4 secondary-title text-orange text-center">Welcome to Wahoo Waterworld</h2>
                                    <div className="div-content">
                                        <p>
                                            Please read the following General Rules and Regulations before your visitation for a safe, memorable and fun experience in Wahoo Waterworld (“Wahoo Waterworld”).
                                        </p>
                                        <p>Safety is our mutual priority. Parents and guardians are responsible for the safe custody or care of their children while in the park. Wahoo Waterworld provides a variety of entertaining activities including pools, shows and new experiences. Please consider the age, height, maturity, strength, weight, and other attributes of persons under your care when choosing a particular activity or experience. Visitors under 12 years of age must be accompanied by a visitor who has attained at least 18 years of age to enter Wahoo Waterworld.</p>
                                        <p>Visitors shall comply with the Wahoo Waterworld  General Rules and Regulations, as well as the specific Rules and Regulations as stipulated at the entrance of premises or facilities including but not limited to parks, rides, pools, attractions, exhibits, transportation, shops, restaurants, car parks, landscape, classrooms and performing venues by notices or directions and requests or directions given by the staff of Wahoo Waterworld or the co-operating partners. Wahoo Waterworld may photograph, film or record CCTV images in the format of video and audio in Wahoo Waterworld or boundary areas under the management of Wahoo Waterworld and use the same for educational or promotional purposes.</p>
                                        <p>Visitor shall pay the applicable admission fee, or have the relevant admission tickets, from time to time required by the  Corporation (“the Corporation”). Wahoo Waterworld reserves the right to require visitor to present identification proof for verification on admission. Visitors failing to comply with admission requirements may be refused admission to Wahoo Waterworld or premises under the management of the Corporation without refund or compensation.</p>
                                    </div>
                                </div>
                                <img src={regulation} className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
}
export default TipsOnWahoo;